module.exports = {
  version: process.env.npm_package_version,
  api: process.env.REACT_APP_API_BASE_URI,
  graphqlApi: process.env.REACT_APP_GRAPHQL_API_BASE_URI,
  prismicUri: process.env.REACT_APP_PRISMIC_URI,
  prismicKey: process.env.REACT_APP_PRISMIC_KEY,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  tagManagerId: process.env.REACT_APP_GTM_ID,
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
  dripKey: process.env.REACT_APP_DRIP_API_KEY,
  dripAccountId: process.env.REACT_APP_DRIP_ACCOUNT_ID,
  contentfulSpace: process.env.REACT_APP_CONTENTFUL_SPACE,
  contentfulKey: process.env.REACT_APP_CONTENTFUL_API_KEY,
  baseUri: process.env.REACT_APP_BASE_URI,
  sitemapUri: 'https://feast-it.com',
  cookieSecure: process.env.REACT_APP_COOKIE_SECURE,
  googleMapsApi: process.env.REACT_APP_GOOGLE_MAPS_API,
  blockCrawlers: process.env.REACT_APP_BLOCK_CRAWLERS === 'true',
  googlePlaces: process.env.REACT_APP_GOOGLE_PLACES,
  headwayId: '7vBg67',
  stripeKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
  plaintextSupplierDescriptions:
    process.env.REACT_APP_PLAINTEXT_SUPPLIER_DESCRIPTIONS === 'true',
  enableEventPageLookups:
    process.env.REACT_APP_ENABLE_EVENT_PAGE_LOOKUPS === 'true',
  deployedEnvironment: process.env.REACT_APP_DEPLOYED_ENVIRONMENT || 'local',
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  mixpanelProjectToken: process.env.MIXPANEL_PROJECT_TOKEN,
};
