import { useMemo } from 'react';
import cookies from 'next-cookies';
import cookiesBrowser from 'js-cookie';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../config';

let apolloClient;

const createApolloClient = (ctx = {}) => {
  let headers = {};
  const accessToken = ctx.headers
    ? cookies(ctx ?? {}).AUTHENTICATION_TOKEN_V2
    : null;

  if (accessToken) {
    headers = {
      Authorization: accessToken,
    };
  }

  const httpLink = createHttpLink({
    uri: config.graphqlApi,
  });

  const authLink = setContext((req, { headers }) => {
    const token = cookiesBrowser.get('AUTHENTICATION_TOKEN_V2');

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    credentials: 'include',
    headers: headers,
  });
};

export const initializeApollo = (initialState = null, ctx) => {
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo = (initialState, ctx) => {
  const store = useMemo(() => initializeApollo(initialState, ctx), [
    initialState,
    ctx,
  ]);
  return store;
};
