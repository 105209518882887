import locale from 'date-fns/locale/en-GB';
import format from 'date-fns/format';
import compose from 'just-compose';

export const DATE_LONG = 'iiii do MMMM yyyy';
export const DATE_MEDIUM = 'do MMMM yyyy';
export const DATE_SHORT = 'dd/MM/yyyy';
export const DATE_NATIVE = 'yyyy-MM-dd';

function withNativeDate(date = null) {
  if (date instanceof Date && !isNaN(date)) {
    return date;
  }
  if (!date) {
    return null;
  }

  const nativeDate = new Date(date);
  if (isNaN(nativeDate)) {
    return null;
  }

  return nativeDate;
}

export const makeFormat = dateFormat =>
  compose(
    withNativeDate,
    date => date && format(date, dateFormat, { locale })
  );

export const formatDateLong = makeFormat(DATE_LONG);
export const formatDateMedium = makeFormat(DATE_MEDIUM);
export const formatDateShort = makeFormat(DATE_SHORT);
export const formatDateNative = makeFormat(DATE_NATIVE);
