import ReactGA from 'react-ga';
import cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';

import config from '../config';
import { convertPathToName } from '../modules/formatting';
import { categories, PAGE_VIEWED } from '../constants/analytics';
import { isImpersonationSession } from './impersonationSession';

let segmentClient = null;

export const getSegmentClient = () => {
  if (segmentClient) return segmentClient;

  const segment = AnalyticsBrowser.load({ writeKey: config.segmentKey });

  segmentClient = segment;

  return segment;
};

export const initGA = () => {
  if (!window.GA_INITIALIZED) {
    ReactGA.initialize(config.googleAnalyticsId);
    window.GA_INITIALIZED = true;
  }
};

export const checkUserIsIdentified = async () => {
  const segmentUserMethods = await segmentClient.user();
  const segmentUser = await segmentUserMethods.traits();

  if (!segmentUser?.email) resetAnalytics();
};

export const trackEvent = (event, props) => {
  const extendedProps = {
    ...props,
    'User Agent': navigator.userAgent,
    isImpersonationSession: isImpersonationSession(),
  };

  segmentClient.track(event, extendedProps);
  window?.userpilot?.track(event, extendedProps);
};

export const trackIdentity = (id, traits, isAnonymousUser) => {
  const extendedTraits = {
    ...traits,
    'User Agent': navigator.userAgent,
    isImpersonationSession: isImpersonationSession(),
  };

  if (isAnonymousUser) {
    segmentClient.identify(extendedTraits);
  } else {
    segmentClient.identify(id, extendedTraits);
  }

  window?.userpilot?.identify(id, extendedTraits);
};

export const trackAlias = id => {
  segmentClient.alias(id);
};

export const mixpanelAlias = userId => {
  mixpanel.alias(userId);
};

export const resetAnalytics = () => {
  segmentClient.reset();
  mixpanel.reset();

  cookies.remove('_cioanonid');
  cookies.remove('_cioid');
  cookies.remove('ajs_anonymous_id');
  cookies.remove('ajs_user_id');

  localStorage.removeItem('ajs_user_traits');
  localStorage.removeItem('ajs_anonymous_id');
  localStorage.removeItem('ajs_user_id');
};

export const trackPageViewed = url => {
  const category = categories.find(({ regex }) => url.match(regex));

  ReactGA.pageview(url);

  if (!category) {
    return;
  }

  const pageViewed = {
    Category: category.type,
    'Page Name': category.pageName || convertPathToName(url),
    'Page URL': url,
    Platform: 'Web',
  };

  trackEvent(PAGE_VIEWED, pageViewed);
};

export const tagManagerTrack = (event, data) => {
  window.dataLayer.push({
    event,
    ...data,
  });
};

export const formatPathId = pathId => {
  if (typeof pathId !== 'string' || !pathId) return null;

  return pathId
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const setAwinCookie = awinUniqueCode => {
  const awinUniqueCodeQuery = 'awc';
  const existingCookie = cookies.get(awinUniqueCodeQuery);

  if (!existingCookie || existingCookie !== awinUniqueCode) {
    cookies.set(awinUniqueCodeQuery, awinUniqueCode);
  }
};
