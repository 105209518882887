export const removeFalsy = object => {
  let newObject = {};

  Object.keys(object).forEach(prop => {
    if (object[prop]) {
      newObject[prop] = object[prop];
    }
  });

  return newObject;
};
