import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Router, { useRouter } from 'next/router';
import qs from 'query-string';
import mixpanel from 'mixpanel-browser';
import { ApolloProvider } from '@apollo/react-hooks';

import {
  checkUserIsIdentified,
  getSegmentClient,
  initGA,
  setAwinCookie,
  trackPageViewed,
} from '../modules/analytics';
import {
  BROWSING_HISTORY_STORAGE,
  GCLID,
  ON_LOAD_URL,
} from '../constants/session-storage-keys';
import { useUser } from '../modules/user';
import { initDatadogRum, initDatadogLogging } from '../modules/monitoring';
import { getEvents } from '../modules/events';
import { useApollo } from '../modules/apollo';
import UserContext from '../context/user';
import CustomerEventsContext from '../context/customerEvents';
import config from '../config';

mixpanel.init(config.mixpanelProjectToken);

Router.events.on('routeChangeComplete', url => trackPageViewed(url));

const App = ({ Component, pageProps }) => {
  const { isLoggedIn, isLoadingUser, user } = useUser();
  const [events, setEvents] = useState([]);
  const apolloClient = useApollo(pageProps.initialApolloState);
  const segment = getSegmentClient();

  // Use asPath to get the query params because the query object from useRouter gets updated on a second render (too late for browsing history functionality)
  const { asPath } = useRouter();
  const { query } = qs.parseUrl(asPath);

  useEffect(() => {
    if (user?.id) {
      getEvents(user.id).then(res => setEvents(res));
    }
  }, [user]);

  useEffect(() => {
    initDatadogLogging();
  }, []);

  useEffect(() => {
    initDatadogRum(user);
  }, [user]);

  useEffect(() => {
    initGA();

    if (
      (window.location.hostname === 'feast-it.com' ||
        window.location.hostname === 'www.feast-it.com' ||
        window.location.hostname === 'tahini.feast-it.com') &&
      (window.location.pathname.includes('/info') ||
        window.location.pathname.includes('/event') ||
        window.location.pathname.includes('/magazine') ||
        window.location.pathname.includes('/suppliers') ||
        window.location.pathname.includes('/contact') ||
        window.location.pathname.includes('/terms') ||
        window.location.pathname.includes('/privacy') ||
        window.location.pathname === '/')
    ) {
      window.location.replace(`https://togather.com`);
    }

    segment.ready(async () => {
      await checkUserIsIdentified();

      trackPageViewed(window.location.pathname);
    });

    const browsingHistory = sessionStorage.getItem(BROWSING_HISTORY_STORAGE);

    if (!browsingHistory) {
      const data = {
        referrer: query?.customerReferralId
          ? 'customerReferral'
          : document.referrer,
        source: query?.utm_source,
        medium: query?.utm_medium,
        campaign: query?.utm_campaign,
        term: query?.utm_term,
        content: query?.utm_content,
      };

      sessionStorage.setItem(BROWSING_HISTORY_STORAGE, JSON.stringify(data));
    }

    const gclid = query?.gclid;
    if (!sessionStorage.getItem(GCLID) && gclid) {
      sessionStorage.setItem(GCLID, gclid);
    }

    const onloadUrl = sessionStorage.getItem(ON_LOAD_URL);

    if (!onloadUrl) {
      sessionStorage.setItem(ON_LOAD_URL, window.location.href);
    }

    if (query?.awc) {
      setAwinCookie(query.awc);
    }
  }, [query]);

  return (
    <UserContext.Provider value={{ user, isLoggedIn, isLoadingUser }}>
      <CustomerEventsContext.Provider
        value={{
          events,
          eventId: query?.eventId,
          eventRequestId: query?.requestId,
        }}
      >
        <ApolloProvider client={apolloClient}>
          <Component
            user={user}
            isLoggedIn={isLoggedIn}
            isLoadingUser={isLoadingUser}
            {...pageProps}
          />
        </ApolloProvider>
      </CustomerEventsContext.Provider>
    </UserContext.Provider>
  );
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default App;
