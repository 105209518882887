import { api } from './api';
import { formatDateMedium } from './date';
import { getBrowsingHistory } from './browsingHistory';
import { getAnalyticsContext } from './analyticsContext';
import {
  getPlaceDetails,
  getExactAddress,
  getApproxAddress,
} from './googlePlaces';
import { removeFalsy } from './object';

export const getEvent = async (eventId, token) => {
  // Because we do it before the AUTHENTICATION_TOKEN_V2 cookie has been set
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

  const { data } = await api.get(`/events/${eventId}`, config);

  return data;
};

export const getEvents = async userId => {
  const { data } = await api.get(`/events/${userId}/user`);
  return data;
};

export const createEventWithRequestsPayload = async ({ event, request }) => {
  const isAutocomplete =
    event.locationAutocomplete?.value && !event.manualLocation;

  if (isAutocomplete) {
    const details = await getPlaceDetails(event.locationAutocomplete.value);
    const address =
      event.locationDecided === 'yes'
        ? getExactAddress(details)
        : getApproxAddress(details);
    event = { ...event, ...address };
  }

  const analyticsContext = getAnalyticsContext();
  const browsingHistory = getBrowsingHistory();

  const eventPayload = removeFalsy({
    analyticsContext: analyticsContext,
    browsingHistory: browsingHistory,
    coordinates: event.coordinates,
    date: event.date,
    start_time: event.start_time,
    end_time: event.end_time,
    freshsalesRequestInfo: event.freshsalesRequestInfo,
    location_city: event.location_city,
    location_line_1: event.location_line_1,
    location_line_2: event.location_line_2,
    postcode: event.postcode,
    tags: event.tags,
    type: event.type,
    urgency: event.urgency,
    supplier_referral: event.supplier_referral,
    customer_referral: event.customer_referral,
    date_flexible: event.date_flexible,
    event_areas: event.event_areas,
  });

  eventPayload.approximate_location = event.locationDecided === 'no';

  const requestPayload = {
    budget: request.budget,
    category_tier1: request.category_tier1,
    category_tier2: request.category_tier2,
    completed: request.completed,
    item_quantity: request.item_quantity,
    tag_uuids: request.tag_uuids,
    description: request.description,
    incomplete_verticals: request.incomplete_verticals,
    analytics_context: analyticsContext,
    additional_info: request.additional_info,
    budget_flexible: request.budget_flexible,
    gclid: request?.gclid,
  };

  return { event: eventPayload, request: requestPayload };
};

export const getEventType = event => event.type || 'Event';

export const getEventDate = event =>
  event.date ||
  event.eventDate ||
  (event.timings && event.timings.date) ||
  null;

export const getEventTitle = event =>
  `${getEventType(event)} on ${formatDateMedium(getEventDate(event))}`;
