import axios from 'axios';
import compose from 'just-compose';

import { captureError } from './monitoring';

export const getLocationAutocomplete = async input => {
  if (!input) {
    return null;
  }

  try {
    const { data } = await axios.get('/api/google/autocomplete', {
      params: {
        input,
      },
    });

    return data?.predictions;
  } catch (error) {
    captureError(error);
  }
};

export const getPlaceDetails = async placeId => {
  if (!placeId) {
    return null;
  }

  try {
    const { data } = await axios.get(`/api/google/place-details/${placeId}`);

    return data?.result;
  } catch (error) {
    captureError(error);
  }
};

export const formatAddressComponents = data => {
  const formattedComponents = {};

  if (data?.geometry?.location) {
    formattedComponents.coordinates = {
      lat: data.geometry.location.lat,
      lon: data.geometry.location.lng,
    };
  }

  data?.address_components?.forEach(field =>
    field.types.forEach(type => (formattedComponents[type] = field.long_name))
  );

  return formattedComponents;
};

export const formatExactAddress = data => ({
  location_line_1: [data.street_number, data.route].filter(Boolean).join(', '),
  location_line_2: data.neighborhood || data.locality,
  location_city: data.postal_town || data.administrative_area_level_2,
  postcode: data.postal_code,
  coordinates: data.coordinates,
});

export const getExactAddress = data =>
  compose(
    formatAddressComponents,
    formatExactAddress
  )(data);

export const formatApproxAddress = data => {
  if (
    (data.locality || data.sublocality) &&
    ((data.postal_code_prefix && data.postal_code) || !data.postal_code)
  ) {
    return {
      location_line_2: data.sublocality,
      location_city: data.postal_town ?? data.locality,
      coordinates: data.coordinates,
    };
  } else if (
    (data.administrative_area_level_1 || data.administrative_area_level_2) &&
    !data.colloquial_area &&
    !data.postal_code
  ) {
    return {
      location_city:
        data.administrative_area_level_2 ?? data.administrative_area_level_1,
      coordinates: data.coordinates,
    };
  } else if (data.colloquial_area) {
    return {
      location_city: data.colloquial_area,
      coordinates: data.coordinates,
    };
  } else {
    return formatExactAddress(data);
  }
};

export const getApproxAddress = data =>
  compose(
    formatAddressComponents,
    formatApproxAddress
  )(data);
