import { baseUri } from '../config';

const MAGAZINE = `/magazine`;

const SUPPLIERS_DASHBOARD = '/suppliers/dashboard';

export default {
  HOME: `/`,
  COMMUNITY: `${baseUri}/info/supplier-community`,
  MAGAZINE,
  MAGAZINE_POST: (id = '[id]') => `${MAGAZINE}/${id}`,
  MAGAZINE_CATEGORY: (id = '[category]') => `${MAGAZINE}/category/${id}`,
  BROWSE: '/suppliers',
  SUPPLIER_PROFILE: (slug = '[slug]') => `/suppliers/${slug}`,
  ENQUIRY_FORM: `/enquiry`,
  VIRTUAL_ENQUIRY_FORM: `/virtual-enquiry`,
  LOGIN:
    process.env.NODE_ENV !== 'development' ? `${baseUri}/login` : '/mock-login',
  SIGN_UP: `${baseUri}/signup`,
  MOCK_LOGIN: `/mock-login`,
  DASHBOARD: `${baseUri}/dashboard`,
  CALENDAR: `${baseUri}${SUPPLIERS_DASHBOARD}/calendar`,
  CUSTOMER_EVENT: eventId => `${baseUri}/dashboard/${eventId}`,
  CUSTOMER_EVENT_REQUEST: (eventId, requestId) =>
    `${baseUri}/dashboard/${eventId}/${requestId}`,
  CUSTOMER_QUOTE: (eventId, requestId, quoteId) =>
    `${baseUri}/dashboard/${eventId}/${requestId}/${quoteId}`,
  CONVERSATION: conversationUuid => `${baseUri}/messages/${conversationUuid}`,
  SUPPLIER_DASHBOARD: `${baseUri}${SUPPLIERS_DASHBOARD}`,
  SUPPLIER_DASHBOARD_ONBOARDING: `${baseUri}${SUPPLIERS_DASHBOARD}/onboarding`,
  SUPPLIER_EVENTS: `${baseUri}${SUPPLIERS_DASHBOARD}/open-events`,
  SUPPLIER_QUOTES: `${baseUri}${SUPPLIERS_DASHBOARD}/my-quotes`,
  SUPPLIER_BOOKINGS: `${baseUri}${SUPPLIERS_DASHBOARD}/my-bookings`,
  SUPPLIER_MY_TEMPLATES: `${SUPPLIERS_DASHBOARD}/my-templates`,
  SUPPLIER_MANAGE_ACCOUNT: `${baseUri}${SUPPLIERS_DASHBOARD}/manage`,
  SUPPLIER_MANAGE_ACCOUNT_SECTION: id =>
    `${baseUri}${SUPPLIERS_DASHBOARD}/manage/${id}`,
  SUPPLIER_PAYMENTS: `${baseUri}${SUPPLIERS_DASHBOARD}/payments`,
  SUPPLIER_SIGN_UP_FORM: `${baseUri}/supplier-sign-up`,
  FAVOURITES: `${baseUri}/my-favourites`,
  PAYMENTS: `${baseUri}/my-payments`,
  MESSAGES: `${baseUri}/messages`,
  SETTINGS: `${baseUri}/settings`,
  FEEDBACK: `https://portal.prodpad.com/bf8f0350-1e4f-11e9-8b8d-0288f735e5b9`,
  REVIEWS: `${baseUri}${SUPPLIERS_DASHBOARD}/reviews`,
  REFER_AN_EVENT: `${baseUri}${SUPPLIERS_DASHBOARD}/refer`,
  CONTACT: `/contact`,
  SITEMAP: `/sitemap`,
  PARTNERS: `/partners`,
  CAREERS: `https://togather.workable.com/`,
  TERMS: `/terms`,
  PRIVACY: `/privacy`,
  PARTNER_TERMS: `/partner-terms`,
  FAQS: `https://togather.freshdesk.com/support/home`,
  INFO: (id = '[id]') => `/info/${id}`,
  INFO_PAGES: {
    ABOUT: `about-us`,
    WHY_FEAST_IT: `why-feast-it`,
    BECOME_PARTNER: `become-a-partner`,
    BECOME_VENUE_PARTNER: `become-a-venue`,
    PICKING_OUR_SUPPLIERS: `picking-our-suppliers`,
    HOW_WE_WORK: `how-we-work`,
    SAFETY_SECURITY: `safety-security`,
  },
  EVENT: (id = '[id]') => `/event/${id}`,
  EVENTS: (id = '[id]') => `/events/${id}`,
  CHRISTMAS: (id = '[id]') => `/christmas/${id}`,
  EVENT_PAGES: {
    WEDDINGS: `wedding`,
    PARTY_CATERING: `party-catering`,
    BBQ_CATERING: `bbq-catering`,
    MOBILE_BARS: `mobile-bars`,
    VIRTUAL_EVENTS: `virtual-experiences`,
    CHRISTMAS_PARTIES: 'corporate-christmas-party',
  },
  VERTICAL: pages =>
    `${baseUri}/events${pages.map(page => `/${page}`).join('')}`,
  UPDATES: 'https://headwayapp.co/togather-updates',
  SOCIAL_PAGES: {
    FACEBOOK: 'https://facebook.com/',
    TWITTER: 'https://twitter.com/',
  },
  CARBON_OFFSET_FORM: `${baseUri}/offset`,
  PUBLIC_EVENTS: `${baseUri}${SUPPLIERS_DASHBOARD}/public-events`,
  REBRAND: `${baseUri}/event/feast-it-becomes-togather`,
};
