import striptags from 'striptags';
import compose from 'just-compose';
import qs from 'query-string';
import slugify from 'slugify';
import parsePhoneNumber from 'libphonenumber-js/min';

export const convertPathToName = path => {
  const str = path.charAt(0) === '/' ? path.substring(1) : path;

  if (!str.length) {
    return 'Homepage';
  }
  return str
    .replace(/-/gi, ' ')
    .replace(/\//gi, ' - ')
    .replace(/^\w/, c => c.toUpperCase());
};

export const convertToSlug = string =>
  slugify(string, { strict: true, lower: true });

export const convertHyphensToSpaces = string => string.split('-').join(' ');

export const convertSpacesToHyphens = string => string.split(' ').join('-');

export const stringifyURL = (url, query) =>
  qs.stringifyUrl({ url, query }, { arrayFormat: 'bracket' });

export const truncateText = (charLimit, ellipsis = false) => text => {
  let truncatedText = text;

  if (truncatedText && truncatedText.length >= charLimit) {
    truncatedText = truncatedText.substr(0, charLimit).trim();

    const disallowedChars = [' ', ',', '.', '/', '?', '!', '&', '-'];
    const lastChar = truncatedText.substr(-1);

    if (disallowedChars.includes(lastChar)) {
      truncatedText = truncatedText.substr(0, truncatedText.length - 1).trim();
    }

    truncatedText += ellipsis ? '…' : '';
  }

  return truncatedText;
};

export const getSentences = numberOfSentences => text => {
  const splitText = text.split(/(\?|\.|!)/);

  let sentences = '';

  for (let i = 0; i < numberOfSentences * 2; i++) {
    if (splitText[i]) {
      sentences = sentences + splitText[i];
    }
  }

  return sentences;
};

export const stripTagsAndGetSentences = (text, numberOfSentences) =>
  compose(
    striptags,
    getSentences(numberOfSentences)
  )(text);

export const stripTagsAndTruncate = (text, charLimit, ellipsis) =>
  compose(
    striptags,
    truncateText(charLimit, ellipsis)
  )(text);

export const toLowerCase = string =>
  string.toUpperCase() === string ? string : string.toLowerCase();

export const toSentenceCase = string =>
  string.replace(/^\w/, c => c.toUpperCase());

export const toTitleCase = string =>
  string
    .split(' ')
    .map(word => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');

export const onlyAlphaChars = (string = '') =>
  string
    .replace(/[^a-zA-Z]/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();

export const initializeSurname = rawText => {
  const newText = onlyAlphaChars(rawText);
  const text = newText || '';
  const namesArray = text.split(' ');
  const capitatizedFirstName =
    namesArray[0].charAt(0).toUpperCase() + namesArray[0].slice(1);
  const surname = namesArray[1] || '';
  const surnameInitial = surname[0] ? surname[0].toUpperCase() : '';
  const name = `${capitatizedFirstName} ${surnameInitial}`;
  return name.trim();
};

export const removeParentheses = string => string.replace(/ *\([^)]*\) */g, '');

export const formatRating = rating => Number(Number(rating).toFixed(1));

export const phoneNumberIsValid = phoneNumber => {
  if (!phoneNumber) return false;

  try {
    const number = parsePhoneNumber(phoneNumber, 'GB');

    return number.isValid();
  } catch (error) {
    return false;
  }
};

export const formatPhoneNumberWithGBCountryCode = phoneNumber => {
  const parsedNumber = parsePhoneNumber(phoneNumber, 'GB');

  if (!parsedNumber) return '';

  return parsedNumber.number;
};

export const formatPhoneNumberWithNoCountryCode = phoneNumber => {
  const parsedNumber = parsePhoneNumber(phoneNumber, 'GB');

  if (!parsedNumber) return '';

  return 0 + parsedNumber.nationalNumber;
};
