import { useState, useEffect } from 'react';
import axios from 'axios';
import cookies from 'js-cookie';

import paths from '../constants/paths';
import { api, cookieSecure } from '../config';
import { captureError } from './monitoring';
import { api as apiService } from './api';
import {
  mixpanelAlias,
  resetAnalytics,
  trackAlias,
  trackIdentity,
} from './analytics';
import { formatPhoneNumberWithGBCountryCode } from './formatting';

const INITIAL_USER = { isLoggedIn: false, user: {}, isLoadingUser: true };

export const getUser = token =>
  axios({
    url: `${api}/users/me`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createUser = async user => {
  try {
    const {
      data: { accessToken, userId, segment },
    } = await apiService.post(`/users/signup`, user);

    setUser(accessToken);
    mixpanelAlias(userId);
    trackAlias(userId);
    trackIdentity(userId, {
      phone: formatPhoneNumberWithGBCountryCode(user.phone),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      Segment: segment,
    });

    return { user: userId };
  } catch (userCreationError) {
    captureError(userCreationError);
    throw userCreationError.response.data;
  }
};

export const setUser = token =>
  cookies.set('AUTHENTICATION_TOKEN_V2', token, {
    ...(cookieSecure && { secure: cookieSecure }),
    expires: 21,
  });

export const login = async userData => {
  setUser(userData.accessToken);

  const { data: user } = await getUser(userData.accessToken);

  trackAlias(userData.userId);
  trackIdentity(userData.userId, {
    phone: formatPhoneNumberWithGBCountryCode(user.phone),
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    'User Type': user.type,
    'Supplier ID': user?.suppliers?.[0]?.uuid,
    'Vertical Tier 1': user?.suppliers?.[0]?.category_tier1,
    'Vertical Tier 2': user.suppliers?.[0]?.tier2?.[0]?.name,
    State: user?.suppliers?.[0]?.state,
  });

  if (process.env.NODE_ENV === 'development') {
    window.location.href = paths.HOME;
  } else {
    const { data } = await getUser(userData.accessToken);

    if (data.type === 'supplier') {
      window.location.href = paths.SUPPLIER_DASHBOARD;
    } else {
      window.location.href = paths.DASHBOARD;
    }
  }
};

export const logout = (redirect = true) => {
  cookies.remove('AUTHENTICATION_TOKEN_V2', {
    ...(cookieSecure && { secure: cookieSecure }),
  });

  resetAnalytics();

  if (redirect) {
    window.location.href = paths.HOME;
  }
};

export const useUser = () => {
  const [user, setUser] = useState(INITIAL_USER);

  useEffect(() => {
    const AUTHENTICATION_TOKEN_V2 = cookies.get('AUTHENTICATION_TOKEN_V2');

    const fetchUser = async () => {
      try {
        const { data } = await getUser(AUTHENTICATION_TOKEN_V2);

        setUser({ isLoggedIn: true, user: data, isLoadingUser: false });
      } catch (error) {
        setUser({ ...INITIAL_USER, isLoadingUser: false });
        const disableRedirect = false;

        logout(disableRedirect);
        captureError(error);
      }
    };

    if (AUTHENTICATION_TOKEN_V2) {
      fetchUser();
    } else {
      setUser({ ...INITIAL_USER, isLoadingUser: false });
    }
  }, []);

  return user;
};
