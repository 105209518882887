import { BROWSING_HISTORY_STORAGE } from '../constants/session-storage-keys';

export function formatBrowsingHistoryObject(query) {
  const queryAsArray = Object.entries(query);

  const browsingHistoryObject = queryAsArray.reduce(
    (acc, utmCode) => {
      const queryKey = utmCode[0];
      const isValueArray = Array.isArray(utmCode[1]);

      return {
        ...acc,
        [queryKey]: isValueArray ? utmCode[1][0] : utmCode[1],
      };
    },
    { referrer: document.referrer }
  );

  return browsingHistoryObject;
}

export const getBrowsingHistory = () => {
  const browsingHistory = JSON.parse(
    sessionStorage.getItem(BROWSING_HISTORY_STORAGE)
  );

  if (browsingHistory) {
    const formatedBrowsingHistory = formatBrowsingHistoryObject(
      browsingHistory
    );
    return [formatedBrowsingHistory];
  }

  return null;
};
