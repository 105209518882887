import cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const isImpersonationSession = () => {
  const authenticationToken = cookies.get('AUTHENTICATION_TOKEN_V2');

  if (!authenticationToken) {
    return false;
  }

  const { impersonationSession } = jwt_decode(authenticationToken);

  return impersonationSession ?? false;
};
