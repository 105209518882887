export const PAGE_VIEWED = 'Page Viewed';
export const GET_QUOTES_CLICKED = 'Get Quotes clicked';
export const NEWSLETTER_SUBSCRIBED = 'Newsletter Subscribed';
export const SUPPLIER_PROFILE_SHARED = 'Supplier Profile Shared';
export const SUPPLIER_PROFILE_VIDEO_PLAYED = 'Supplier Profile Video Played';
export const SUPPLIER_MAP_CLICKED = 'Supplier map clicked';
export const ENQUIRY_FORM_NEXT_CLICKED = 'Enquiry Form Next Clicked';
export const ENQUIRY_FORM_BACK_CLICKED = 'Enquiry Form Back Clicked';
export const ENQUIRY_FORM_CLOSE_CLICKED = 'Enquiry Form Close Clicked';
export const ENQUIRY_FORM_ABANDONED = 'Enquiry Form Abandoned';
export const ENQUIRY_FORM_ABANDONED_REASON = 'Enquiry Form Abandoned Reason';
export const ENQUIRY_FORM_MANUAL_ADDRESS_CLICKED =
  'Enquiry Form Manual Address Clicked';
export const ENQUIRY_FORM_AUTOCOMPLETE_ADDRESS_CLICKED =
  'Enquiry Form Autocomplete Address Clicked';
export const ENQUIRY_LOGIN_CLICKED = 'Enquiry Login Link Clicked';
export const VIRTUAL_EVENT_SUBMITTED = 'Virtual Event Submitted';
export const CARBON_FORM_NEXT_CLICKED = 'Carbon Form Next Clicked';
export const CARBON_FORM_BACK_CLICKED = 'Carbon Form Back Clicked';
export const CARBON_FORM_CLOSE_CLICKED = 'Carbon Form Close Clicked';
export const CARBON_FORM_ABANDONED = 'Carbon Form Abandoned';

export const SUPPLIER_FORM_NEXT_CLICKED = 'Supplier Form Next Clicked';
export const SUPPLIER_FORM_BACK_CLICKED = 'Supplier Form Back Clicked';
export const SUPPLIER_FORM_CLOSE_CLICKED = 'Supplier Form Close Clicked';
export const SUPPLIER_FORM_ABANDONED = 'Supplier Form Abandoned';
export const SUPPLIER_FORM_MANUAL_ADDRESS_CLICKED =
  'Supplier Form Manual Address Clicked';
export const SUPPLIER_FORM_AUTOCOMPLETE_ADDRESS_CLICKED =
  'Supplier Form Autocomplete Address Clicked';
export const SUPPLIER_FORM_START_CLICKED = 'Supplier Form Get Started Clicked';
export const SUPPLIER_APPLICATION_SUBMIT = 'Supplier submitted application';
export const FAVOURITE_ADDED = 'Favourite Added';
export const EVENT_REQUEST = 'Event Request';
export const CATERING_ABOUT_EVENT_EXPERIEMENT =
  'Customer Catering About Event Page Viewed';
export const SUBMITTED_REQUEST_ATTRIBUTION =
  'Enquiry Request Sumbitted Attribution';
export const WEDDING_CALCULATOR_NEXT_CLICKED =
  'Wedding Calculator Next Clicked';
export const WEDDING_CALCULATOR_BACK_CLICKED =
  'Wedding Calculator Back Clicked';
export const WEDDING_CALCULATOR_CLOSE_CLICKED = 'Wedding Calculator Clicked';
export const WEDDING_CALCULATOR_ABANDONED = 'Wedding Calculator Abandoned';
export const SIGN_UP_WALL_OPENED = 'Sign Up Wall Triggered';

export const categories = [
  {
    regex: /^\/$/gm,
    type: 'Homepage',
  },
  {
    regex: /\/enquiry/g,
    type: 'Enquiry Flow',
  },
  {
    regex: /\/event\//g,
    type: 'Landing Page',
  },
  {
    regex: /\/events\//g,
    type: 'Automated Landing Page',
  },
  {
    regex: /\/info/g,
    type: 'Info Page',
  },
  {
    regex: /\/join/g,
    type: 'Join Page',
  },
  {
    regex: /\/magazine/g,
    type: 'Magazine',
  },
  {
    regex: /\/suppliers(?!\/)/g,
    type: 'Browse Suppliers',
  },
  {
    regex: /(\/suppliers\/)(?!dashboard)/g,
    type: 'Supplier Profile',
    increment: true,
  },
];
