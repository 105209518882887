import axios from 'axios';
import cookie from 'js-cookie';

import { api as baseURL } from '../config';

export const api = axios.create();

api.interceptors.request.use(config => {
  const token = cookie.get('AUTHENTICATION_TOKEN_V2');

  config.baseURL = baseURL;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
